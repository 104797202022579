.App {
  text-align: center;
}

button {
  margin-left: 5px;
  cursor: pointer;
}

.table-container {
  width: 80%;
}

table {
  margin: 2rem auto;
  text-align: start;
}

code {
  margin-left: 2rem;
}
